import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import Backyard from '../../assets/images/projects/backyard.jpg'
import MasterBedroom from '../../assets/images/projects/master_bedroom.jpg'
import GuestBedroom from '../../assets/images/projects/guest_bedroom.jpg'
import FamilyRoom from '../../assets/images/projects/family_room.jpg'
import LivingRoom from '../../assets/images/projects/living_room.jpg'
import Kitchen from '../../assets/images/projects/kitchen.jpg'
import Bathroom from '../../assets/images/projects/bathroom.jpg'
import Office from '../../assets/images/projects/office.jpg'
import DinningRoom from '../../assets/images/projects/dinning_room.jpg'


const Rooms = () => {
    return (
        <section className="gallery-area bg-color pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Recent Projects
                    </span>
                    <h2>Check Our Ideas for Your Home</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={MasterBedroom} alt="project" />

                                <Link className="link-btn" to="/Master Bedroom">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Master Bedroom">
                                        Master Bedroom
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={FamilyRoom} alt="project" />

                                <Link className="link-btn" to="/Family Room">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Family Room">
                                        Family Room
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={LivingRoom} alt="project" />

                                <Link className="link-btn" to="/Living Room">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Living Room">
                                        Living Room
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={Kitchen} alt="project" />

                                <Link className="link-btn" to="/Kitchen">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Kitchen">
                                        Kitchen
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={Bathroom} alt="project" />

                                <Link className="link-btn" to="/Bathroom">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Bathroom">
                                        Bathroom
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={Backyard} alt="project" />

                                <Link className="link-btn" to="/Backyard">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Backyard">
                                        Backyard
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={Office} alt="project" />

                                <Link className="link-btn" to="/Office Room">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Office Room">
                                        Office Room
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={DinningRoom} alt="project" />

                                <Link className="link-btn" to="/Dinning Room">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Dinning Room">
                                        Dinning Room
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={GuestBedroom} alt="project" />

                                <Link className="link-btn" to="/Guest Bedroom">
                                    <i className='bx bx-building-house'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/Guest Bedroom">
                                        Guest Bedroom
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Rooms;