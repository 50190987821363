import React from "react"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Rooms from "../components/Index/Rooms"

const Home = () => {
  return (
    <Layout>
      <NavbarStaging />
      <Rooms />
      <Footer />
    </Layout>
  )
}

export default Home